import Head from "next/head";
import * as React from "react";
import "../styles/globals.css";
import type { AppProps } from "next/app";
import "bootstrap/dist/css/bootstrap.css";
import GoogleTagManager from "../components/Shared/GoogleTagManager";
import LeadProvider from "../lib/context/lead";
import KeywordProvider from "../lib/context/keyword";
import Script from "next/script";

const MyApp = ({ Component, pageProps }: AppProps) => {
	return (
		<>
			<Head>
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1, maximum-scale=1"
				/>
				<meta name="theme-color" content="#00263A" />
				<link rel="icon" type="image/png" sizes="32x32" href="/favicon.png" />
				<link rel="icon" type="image/png" sizes="16x16" href="/favicon.png" />
			</Head>
			<Script
				id="google-optimize"
				src={`https://www.googleoptimize.com/optimize.js?id=${process.env.GOOGLE_OPTIMIZE}`}
			/>
			<noscript>
				<iframe
					width="0"
					height="0"
					style={{ display: "none", visibility: "hidden" }}
					src={`https://www.googletagmanager.com/ns.html?id=${process.env.GOOGLE_ANALYTICS}`}
				/>
			</noscript>
			<GoogleTagManager />
			<div>
				<LeadProvider>
					<KeywordProvider>
						<Component {...pageProps} />
					</KeywordProvider>
				</LeadProvider>
			</div>
		</>
	);
};

export default MyApp;
